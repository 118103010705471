@import "~@ui/styles/tools";

.document {
    inset: 0;
    width: 100%;
    height: calc(100vh - 180px);
    border: none;
    margin-top: 26px;
}

.footer {
    box-shadow: 0px -12px 16px rgba(0, 0, 0, 0.02);
}

.sections {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 30px;
    margin: 0 4vw;
}

.brokers-title {
    font-weight: 500;
    font-size: 24px;
    line-height: 126%;
    letter-spacing: 0px;
    margin-bottom: 15px;
}

.brokers {
    display: flex;
    gap: 10px;
}

.reports {
    display: flex;
    gap: 4vw;
}

@include media-laptop {
    .sections {
        margin: 0;
        gap: 15px;
    }
}

@include media-table-large {
    .footer {
        padding-left: 12px;
        padding-right: 12px;
    }

    .reports {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
        grid-gap: 15px;
        grid-auto-flow: column;
    }
}

@include media-tablet {
    .brokers,
    .brokers-title,
    .reports  {
        display: none;
    }
}