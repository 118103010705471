@import "../../styles/tools";

$size: 3px;

.wrapper {
    border-radius: 0 12px 12px 0;
    inset: 0 !important;
}

.wrapper--wide {
    width: 100%;
}

.scroll-track,
.scroll-thumb {
    transition: opacity 0.2s ease-in-out;
}

.scroll-track {
    background: $color-input-background !important;
    border-radius: $size !important;
}

.scroll-thumb {
    background: $color-primary !important;
    border-radius: $size !important;
}

.scroll-track--vertical {
    left: 0;
    top: 0 !important;
    width: $size !important;
    height: 100% !important;
    border-radius: $size !important;
}

.scroll-track--vertical--right {
    left: calc(100% - $size);
}

.scroll-track--horizontal {
    height: $size !important;
}

.scroll-track--hidden,
.scroll-track--hidden .scroll-thumb {
    opacity: 0.2;
}

.scroll-track--max-hidden,
.scroll-track--max-hidden .scroll-thumb {
    opacity: 0;
}

.scroll:hover {
    .scroll-track--hidden,
    .scroll-track--hidden .scroll-thumb {
        opacity: 1;
    }
}
